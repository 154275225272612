import React from 'react';
import venkit from '../assets/images/venkit.png';
import eldo from '../assets/images/eldo.png'

function Coach() {
  return (
    <div className='py-20 xl:px-40 md:px-10 px-5 flex flex-col justify-center items-center gap-10'>
        <div className='md:py-12 py-8 flex flex-col items-center justify-center'>
            <div className='text-5xl font-bold text-center'>Meet your Coach</div>
            <div className='md:w-[300px] w-[150px] h-2 bg-purple-600 z-0'></div>
        </div>
        <div className='flex bg-[#5d62ff] text-white rounded-xl flex-col  md:flex-row justify-center items-center'>
            <img className='w-[300px]' src={venkit} alt='venkit'/>
            <div className=' text-left flex flex-wrap flex-col md:px-10 px-2 py-4 items-left bottom-0'>
              <h1 className='md:text-5xl text-3xl font-bold'>Prof. (Dr) P R Venkitaraman</h1>
              <div className='pt-5 text-gray-200'>
                <div>
                Conducted about 6800 Career Seminars. Conducted nearly 150 International Seminars in over 9 countries. Conducted career programs in about 1800 Colleges & Schools. Was Career Consultant to Times of India and provided answers more than 15,000 career queries. Served as career Columnist to Malayala Manorama and wrote 167 articles.
                Appeared in 270 TV shows in Amrita TV, MM TV & others. Serving All India radio for the last 10 years – so far conducted 3450 episodes. Conducted NDAT ( Career Aptitude Test ) for more than 6000 students and counseled more than 11,000 students. Free Career Tele counseling for the last 9 years. Contact for free counseling  at 0484 2319627 between 8 &9 pm on all Wednesdays and Fridays.</div>
                </div>
                <div>
              </div>
            </div>
        </div>
        <div className='flex bg-[#5d62ff] text-white rounded-xl flex-col  md:flex-row-reverse justify-center items-center'>
            <img className='w-[300px]' src={eldo} alt='eldo'/>
            <div className=' md:text-right text-left md:px-10 px-2 py-4 flex flex-col justify-center items-right pt-10'>
              <h1 className='md:text-5xl text-3xl font-bold'>Eldo George Varghese</h1>
              <div className='pt-5 text-gray-200'>
              <p>Founded the the fast growing community called the Top One Percent Lab where students have been shaped to the top one percent class of the society. Founded the the fast growing community called the Top One Percent Lab where students have been shaped to the top one percent class of the society.</p>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Coach
import React from 'react';
import image from '../assets/images/VE.png';

function Hero() {
  return (
    <div className=' flex flex-col pb-10 justify-center items-center text-center'>
        <div className='relative w-full md:py-20 py-16 overflow-hidden text-white bg-black flex flex-col justify-center items-center'>
            <img className='absolute z-0 bg-cover opacity-30' src='https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt='img' />
            <div className=' absolute z-10 w-screen h-screen bg-gradient-to-bl from-blue-600 to-purple-500 opacity-30'></div>
            <div className='relative z-10 md:text-4xl text-2xl font-bold lg:px-40 px-5 capitalize text-blue-100'>Start what Career is apt for you in</div>
            <div className='z-10 capitalize font-bold lg:text-8xl text-5xl text-white bg-blue-500 px-8 pb-5 mt-3 rounded-md'>less than 30 days</div>
            <div className=' py-5 z-10 text-xl italic font-bold md:px-5 lg:px-44 text-blue-100'>Learn how to turn your knolwedege , skills and expertise in a correct career that is being apt for you & get to know how it could be linked back to your present academics</div>
        </div>
        <div  className="flex flex-col justify-center items-center py-20 gap-12">
            <div className='flex flex-col justify-start items-center'>
                <div className='md:text-5xl text-4xl font-bold z-10'>Why attend this workshop ?</div>
                <div className='w-[300px] h-2 bg-purple-600 z-0'></div>
            </div>
            <div className='flex flex-wrap text-white gap-8 text-left justify-center'>
                <div className='w-80 text-xl   bg-[#5d62ff] px-8 py-4 rounded-xl'>Get the exact steps in building the right career by knowing your niche (career) and micro niche.</div>
                <div className='w-80 text-xl   bg-[#5d62ff] px-8 py-4 rounded-xl'>Getting strategies to scale your career to greater heights, by  changing your mindset.</div>
                <div className='w-80 text-xl   bg-[#5d62ff] px-8 py-4 rounded-xl'>Learn how to accelerate the journey of success by learning how to deliver breakthrough results for your child.</div>
                <div className='w-80 text-xl   bg-[#5d62ff] px-8 py-4 rounded-xl'>Learn the exact strategies of highly successful students who have pursued their dream career.</div>
            </div>
        </div>
        <div className='lg:h-[80vh] lg:w-auto w-[300px] md:w-[500px] lg:mx-80 drop-shadow-md'>
            <img className='rounded-xl' src={image} alt="img" />
        </div>
    </div>
  )
}

export default Hero
import React from 'react'

function Examples() {
  return (
    <div className='md:px-20 bg-[#0d1c29]'>
        <div className='py-12 px-5 flex flex-col items-center justify-center'>
            <div className='text-5xl font-bold text-white'>Examples of what you child can make a major shift</div>
            <div className='w-[300px] h-2 bg-purple-600 z-0'></div>
        </div>
        <div className='flex flex-wrap justify-center items-center md:px-10 md:py-10 py-2 gap-5 text-white'>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Mindset</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Lifestyle habits</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Self learning</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Personal development</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Cutting from the digital screen</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Improved focus</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Making use of time</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Structuring the future road map</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Linking back to the present academics</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Making what are the important tasks for the day</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>A sense of thankfulness for the one who have helped in your life</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Get access to bonus worth ₹88888</div>
            <div className='px-8 py-2 bg-[#5d62ff] rounded-full text-sm md:text-xl'>Top One Percent Model</div>
        </div>
    </div>
  )
}

export default Examples
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Main from './pages/main';
import RegisterPage from './pages/register_page';
import Success from './pages/success';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main/>}/>
          <Route path='/register' element={<RegisterPage/>}/>
          <Route path='/success' element = {<Success/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import Navbarss from '../components/navbar';
import axios from 'axios';
// import { resolvePath } from 'react-router-dom';

function RegisterPage() {

    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [number, setnumber] = useState('')

    //check payment status
    const checkstatus = async (id) =>{
      console.log(id)
      const {status} = await axios.post('http://localhost:3001/status', {id})
      console.log(status.data.status)
      return status.data.status;
    }

    //payment request token
    const getToken = async () => {
      const { data } = await axios.post(`http://localhost:3001/token`);
      return data.access_token;
    };
    
    //payment
    const payment = async (event) => {
      event.preventDefault();

      //token call function
      getToken()
        .then((token) => {
          const datas = {
            name,
            email,
            number
          };

          console.log(token)

          axios.post(`http://localhost:3001/pay`, {datas, token}, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
            .then(res => {
              console.log(res.data.payment_request.longurl);
              // window.open(res.data.payment_request.longurl, "_blank");
             
              //status call function
              checkstatus(res.data.payment_request.id)
              .then((pay_status) => {
                if(pay_status === true){
                  window.open('http://localhost:3000/success', "_blank");
                }else{
                  alert("Payment failed")
                } 
              })
              
              window.localStorage.clear();
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    };

  return (
    <div>
    <Navbarss/>
    <div className='flex flex-col justify-center items-center'>
        <form className='flex flex-col items-center justify-center gap-5 bg-slate-300 py-12 rounded-lg w-[310px] md:w-[350px] lg:[350px]' onSubmit={payment} method='POST'>
            <h1 className='text-3xl pb-5'>Register Now</h1>
            <label className='flex flex-col gap-3 w-[270px] md:w-[300px] lg:w-[300px]'>
                <p>Enter your Name</p>
                <input className='p-2 rounded-lg' onChange={(e)=>{setname(e.target.value)}} value={name} type='text' name='name' placeholder='name' required/>
            </label>
            <label className='flex flex-col gap-3 w-[270px] md:w-[300px] lg:w-[300px]'>
                <p>Enter your Email</p>
                <input className='p-2 rounded-lg' onChange={(e)=>{setemail(e.target.value)}} value={email} type='email' name='email' placeholder='email' required/>
            </label>
            <label className='flex flex-col gap-3 w-[270px] md:w-[300px] lg:w-[300px]'>
                <p>Enter your WhatsApp Number</p>
                <input className='p-2 rounded-lg' onChange={(e)=>{setnumber(e.target.value)}} value={number} type='text' name='number' placeholder='+91-xxxxx-xxxxx' required/>
            </label>
            <button className='px-4 py-2 bg-slate-400 rounded-lg' type='submit'>Submit</button>
        </form>
        <div className=' absolute flex flex-col justify-center items-center content-center gap-5 bg-slate-900 p-5 w-screen bottom-0'>
            <div className='text-white text-sm'>© Copyright Eldo George. All Rights Reserved</div>
        </div>
    </div>
    </div>
  )
}

export default RegisterPage